<template>
    
    <!-- Structure -->
	<v-container class= "ma-0 pa-0 main_container" fluid ref="main_container" style="height: 85vh; max-height: calc(100vh - 80px); padding: 0 1vw 0 !important;">
    
        <!-- Toolbar -->
        <v-row class="ma-0 pa-0"><v-col class="ma-0 pa-0" style="min-height: 56px; height: 8vh"><v-toolbar dense>
            <v-toolbar-title>Échecs</v-toolbar-title>
            <v-spacer></v-spacer>
            <span>{{ (player !== null && index !==null) ? ('Joueur : ' + player.alias + ' - Partie : ' + index) : ('') }}</span>
            <v-tooltip bottom v-if="(player || game) ? (true) : (false)">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="blue"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="clear_all">
                            <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </template>
                <span>Quitter la partie</span>
            </v-tooltip>
        </v-toolbar></v-col></v-row>
        
		<v-row align="center" class="ma-0 pa-0"<v-col class="ma-0 pa-0" style="min-height: 56px; height: 8vh">
			<v-alert border="left" dense dismissible text transition="fade-transition" type='error' v-model="error_alert">{{ error }}</v-alert>
            <v-alert border="left" dense dismissible text transition="fade-transition" type='success' v-model="success_alert">{{ success }}</v-alert>
			<v-alert border="left" dense dismissible text transition="fade-transition" type='warning' v-model="warning_alert">{{ warning }}</v-alert>
		</v-col></v-row>
        
        <v-row class="ma-0 pa-0" ref="chessboard_container">
        <v-sheet class="chessboard_sheet" rounded v-if="!game">
        <v-dialog v-if="(player !==null && game !== null) ? (false) : (true)" v-model="meet_dialog" :width="dialog_width">
                <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom >
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                class="ma-2"
                                color="blue"
                                dark
                                outlined
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog }">
                                    <v-icon>mdi-lan-connect</v-icon>
                            </v-btn>
                        </template>
                        <span>Rejoindre une partie</span>
                    </v-tooltip>
                </template>
                <v-card>
                    <v-card-title>
                        Échecs : rejoindre une partie
                    </v-card-title>
                    <v-card-text>
                        <v-alert border="left" dense dismissible text transition="fade-transition" type="error" v-if="dialog_alert">
                            {{ dialog }}
                        </v-alert>
                        <span>Veuillez choisir un alias et renseigner le numéro de la partie que vous voulez rejoindre.</span>
                        <v-form
                            lazy-validation
                            name="meet_form"
                            ref="meet_form"
                            @submit="meet_save">
                                <v-text-field
                                    label="ALIAS"
                                    name="alias_p1"
                                    required
                                    v-model="alias">
                                </v-text-field>
                                <v-text-field
                                    label="PARTIE N°"
                                    name="meet_id"
                                    required
                                    type= 'number'
                                    v-model.number="meet_id">
                                </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" dark text @click="meet_cancel">Annuler</v-btn>
                        <v-btn color="blue" dark text @click="meet_save">Enregistrer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-if="player !== null ? false : true" v-model="start_dialog" :width="dialog_width">
                <template v-slot:activator="{ on: dialog, attrs }">
                    <v-tooltip bottom >
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                class="ma-2"
                                color="blue"
                                dark
                                outlined
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog }">
                                    <v-icon>mdi-play-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Créer une partie</span>
                    </v-tooltip>
                </template>
                <v-card>
                    <v-card-title>
                        Échecs : créer une partie
                    </v-card-title>
                    <v-card-text>
                        <v-alert border="left" dense dismissible text transition="fade-transition" type="error" v-if="dialog_alert">
                            {{ dialog }}
                        </v-alert>
                        <span>Veuillez choisir un alias.</span>
                        <v-form
                            lazy-validation
                            name="start_form"
                            ref="start_form"
                            @submit="start_save">
                                <v-text-field
                                    label="ALIAS"
                                    name="alias_p1"
                                    required
                                    v-model="alias">
                                </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue" dark text @click="start_cancel">Annuler</v-btn>
                        <v-btn color="blue" dark text @click="start_save">Enregistrer</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        
        </v-sheet>
        <v-col class="pa-0" style="height: 69vh; max-height: min(calc(85vh - 112px), calc(100vh - 192px));" v-if="!load && game"><v-responsive :aspect-ratio="1/1" :max-width="'min('+this.chessboard_container_width+'px, calc(85vh - 112px), calc(100vh - 192px))'" width="69vh" style="margin: 0 auto !important;">
		<v-row align="stretch" no-gutters>
	  		<v-col align="center" class="chessboard justify-start" :key="n" :style="(Math.floor((n-1)/8) + (n-1) % 8) & 1 ? 'background-color: #FF9800' : 'background-color: #FFF3E0'" v-if="!load" v-for="n in 64">
				
				<drop :accepts-data="(data) => options(data.index, n-1) !== -1" @drop="dnd((n-1), $event)">
      <drag :class="(game) ? ((game.chessboard[n-1].piece) ? ('drag-in') : ('drag-off')) : ''" :data="game ? {index : (n-1)} : ''" go-back><v-responsive :aspect-ratio="1/1" class="responsive"><img :src="(game) ? ((game.chessboard[n-1].piece) ? (require('../assets/img/chess/' + game.chessboard[n-1].piece.img + '.svg')) : ('')) : ('')" width="80%" onmousedown="return false"></v-responsive></drag>
      </drop>
					
				
	  		</v-col>
		</v-row>
		</v-responsive></v-col></v-row>
        
	</v-container>


</template>

<script>

import * as socketioclient from 'socket.io-client';
import user_service from "../services/user.service.js";
import { Drag, Drop, DropMask } from "vue-easy-dnd";

var error_alert_timer;
var warning_alert_timer;
var success_alert_timer;

export default {
	name: 'chess',
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handle_resize);
    },
	components: {
	    Drag,
	    Drop,
	    DropMask
	},
    computed: {
        dialog_width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '90%'
                case 'sm': return '80%'
                case 'md': return '75%'
                case 'lg': return '70%'
                case 'xl': return '50%'
                default: return '75%'
            }
        },
    },
	created() {

	},
	data: () => ({
        alias: '',
        chessboard_container_width: 0,
        dialog: '',
        dialog_alert: false,
		error: null,
		error_alert: false,
		game: null,
        index: null,
        load: false,
        meet_dialog: false,
        meet_id: null,
		player: null,
		socket: null,
        start_dialog: false,
		success: null,
		success_alert: false,
		warning: null,
		warning_alert: false,
	}),
	methods: {
        alert_display(alert_type) {
            switch(alert_type) {
                case 'error' :
                    this.error_alert = true;
                    error_alert_timer = setTimeout(() => (this.error_alert = false), 5000);
                    break;
                case 'warning' :
                    this.warning_alert = true;
                    warning_alert_timer = setTimeout(() => (this.warning_alert = false), 5000);
                    break;
                case 'success' :
                    this.success_alert = true;
                    success_alert_timer = setTimeout(() => (this.success_alert = false), 5000);
                    break;
            }
        },
        clear_alert_display(alert_type) {
            switch(alert_type) {
                case 'error' :
                    this.error_alert = false;
                    clearTimeout(error_alert_timer);
                    break;
                case 'warning' :
                    this.warning_alert = false;
                    clearTimeout(warning_alert_timer);
                    break;
                case 'success' :
                    this.success_alert = false;
                    clearTimeout(success_alert_timer);
                    break;
            }
        },
        clear_all(){
            this.clear_alert_display('error');
            this.clear_alert_display('success');
            this.clear_alert_display('warning');
            localStorage.removeItem('game');
            localStorage.removeItem('index');
            localStorage.removeItem('player');
            this.alias = '';
            this.dialog = null;
            this.dialog_alert= false;
            this.error = null;
            this.error_alert= false;
            this.game = null;
            this.index = null;
            this.load = true;
            this.player = null;
            this.start_dialog = false;
            this.success = null;
            this.success_alert= false;
            this.warning = null;
            this.warning_alert= false;
        },
		connection() {
			this.socket === null ? this.socket = socketioclient(process.env.VUE_APP_API_URL+'chess') : this.socket = null;
            this.socket.on('connect', () => {
                if (this.index !== null && this.player !== null) {
                    this.socket.emit('reconnect', {index: this.index, player: this.player});
                }
                else {
                
                }
            });
			this.socket.on('game', (data) => {
                this.load = true;
                this.game = data.game;
                let parsed = JSON.stringify(this.game);
                localStorage.setItem('game', parsed);
                this.$nextTick(() => {
                    this.load = false;
                });
			});
            this.socket.on('index', (data) => {
				this.index = data.index;
                let parsed = JSON.stringify(this.index);
				localStorage.setItem('index', parsed);
			});
            this.socket.on('message', (data) => {
                this.clear_alert_display('error');
                this.clear_alert_display('success');
                this.clear_alert_display('warning');
				switch(data.type) {
                    case 'error':
						this.error = data.content;
						this.alert_display('error');
					break;
					case 'success':
						this.success = data.content;
						this.alert_display('success');
					break;
					case 'warning':
						this.warning = data.content;
						this.alert_display('warning');
					break;
				};
			});
			this.socket.on('player', (data) => {
					this.player = data.player;
					let parsed = JSON.stringify(this.player);
					localStorage.setItem('player', parsed);
			});
		},
		dnd(pos, event) {
            this.move({position: event.data.index, newposition: pos});
        },
        handle_resize() {
            this.load = true;
            this.chessboard_container_width =  this.$refs.chessboard_container.clientWidth;
            this.$nextTick(() => {
                this.load=false;
            });
        },
		async move(data) {
			if (this.game) {
				data.player = this.player;
				data.index = this.index;
				this.socket.emit('move', data);			
			} else {
				this.warning = 'Veuillez vous connecter à une partie avant d\'effectuer un mouvement.';
				this.warningalert = true;
			}
		},
		async meet(data) {
			if (this.game) {
				this.warning = 'Vous êtes déjà connecté à une partie.';
				this.warningalert = true;	
			} else {
				this.socket.emit('room', data.index);
				this.socket.emit('meet', data); 
			}
		},
        meet_cancel() {
            this.meet_dialog = false;
            this.alias = '';
            this.meet_id = null;
            this.dialog = '';
            this.dialog_alert = false;
        },
        meet_save() {
			if (this.alias !== '' && this.meet_id !== -1) {
                this.meet({index: this.meet_id, alias: this.alias});		
			} else {
				this.dialog = 'Votre alias doit comporter au moins un caractère et/ou vous devez sélectionner une partie à rejoindre.';
				this.dialog_alert = true;
			}
		},
        options(index, target) {
            let opt = [];
            if (this.game.chessboard[index].piece) {
                switch (this.game.chessboard[index].piece.title) {
                    case 'pion':
                        if (this.game.chessboard[index].piece.color=='noir' && (index+8<64)) {
                            opt.push(index+8);
                        } 
                        if (this.game.chessboard[index].piece.color=='blanc' && (index-8>-1)) {
                            opt.push(index-8);
                        }
                        if ((index<16) && this.game.chessboard[index].piece.color=='noir') {
                            opt.push(index+16);
                        }
                        if ((index>46) && this.game.chessboard[index].piece.color=='blanc') {
                            opt.push(index-16);
                        }
                    break;
                    case 'tour':

                    break;

                }		
            }
            return opt.indexOf(target);
        },
		async start(alias) {
            this.start_cancel();
            this.socket.emit('start', alias);
		},
        start_cancel() {
            this.start_dialog = false;
            this.alias = '';
            this.dialog = '';
            this.dialog_alert = false;
        },
        start_save() {
			if (this.alias !== '') {
                this.start({alias: this.alias});		
			} else {
				this.dialog = 'Votre alias doit comporter au moins un caractère.';
				this.dialog_alert = true;
			}
		},
    },
	mounted() {
		if (localStorage.getItem('game')) {
			this.game = JSON.parse(localStorage.getItem('game'));
		}   	
		if (localStorage.index) {
			this.index = localStorage.index;
		}
		if (localStorage.getItem('player')) {    		
			this.player = JSON.parse(localStorage.getItem('player'));
		}
		this.connection();
        this.chessboard_container_width =  this.$refs.chessboard_container.clientWidth;
        window.addEventListener('resize', this.handle_resize);
	},
	watch: {
        
	},
}
</script>

<style>

.chessboard {
    flex-basis: 12.5%;
}

.chessboard_sheet {
    border: 2px dotted grey !important; 
    display: flex;
    flex: 1;
    height: 69vh; 
    max-height: min(calc(85vh - 112px), calc(100vh - 192px));
    align-items: center;
    justify-content: center;
}

.chessboard_sheet:hover {
    background-color: #E3F2FD;
    border-color: #2196F3 !important;
}

.main_container {
  flex-direction: column !important;
  align-items: initial !important;
}

.container >.row {
  
}

.drag-in:hover {
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.4);
}

.drag-off:hover {
    cursor: default !important;
    box-shadow: none !important;
}

.copy {
  
}

.drop-allowed {
  background-color: rgba(100, 221, 23, 0.5);
}

.drop-forbidden {

}

.drop-in {
  box-shadow: 0 0 5px rgba(0, 0, 255, 0.4);
}

.component_toolbar {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    z-index: 2;
}

.responsive {
    align-items: center;
    display:flex;
}

</style>



